<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)" scroll="true">
      <ion-header>
        <ion-toolbar>
          <ion-title>Payment</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card class="main-card">
        <ion-card-header>
          <router-link :to="{ path: `/member/dashboard` }"
            ><img src="assets/icon/back.svg" width="26" style="float: left"
          /></router-link>

          <ion-card-title>Payment</ion-card-title>

          <a class="router-button" style="/* margin-right: 12px; */">Pay Now</a>
          <!-- <a href="" class="router-button unselected">Medical Aid</a> -->

          <p>Available times Mon-Fri {{tradingStart}}-{{tradingEnd}}</p>
          <p v-if="!tradingHours">A doctor will call you within first hour of opening</p>
        </ion-card-header>

        <ion-card-content>
          <div class="pay">
            <p style="text-align: left; margin-top: 28px; margin-bottom: 7px">
              Payment Options
            </p>
            <!-- <div class="option">
              <img src="/assets/icon/snapscan-logo.svg" width="70">
            </div> -->
            <div class="option selected">
              <img src="/assets/icon/payfast-logo.svg" width="60" />
            </div>

            <div class="paysoft">
              <form ref="form" @submit.prevent="submit">
                <!-- <span class="p-float-label">
                  <InputText id="card" type="card"  required />
                  <label for="card">Credit Card Number</label>
                </span>
                <div class="date-picker-div">
                  <label class="date-label" for="expiry">Expiry Date</label>
                  <datepicker id="expiry" v-model="form.expiry" typeable="true" inputFormat="yyyy/MM/dd" required class="date"/>
                </div>
                <span class="p-float-label">
                  <InputText id="cvv" type="cvv"  required />
                  <label for="cvv">CVV</label>
                </span> -->

                <strong style="display: none" id="promo-code-message"
                  >This promo code does not exist</strong
                >

                <span class="p-float-label">
                  <InputText id="promo" type="promo" v-model="payload.code" />
                  <label for="promo">Promo Code</label>
                </span>

                <ion-button
                  style="width: auto"
                  size="small"
                  type="submit"
                  shape="round"
                  >Apply Promo Code</ion-button
                >

                <h1>Total: R{{ myData.amount }}</h1>

                <!-- `/member/consult/complaint` -->
                <a
                  v-bind:href="
                    'https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=18798171&amp;item_name=Consult&amp;amount=' +
                    myData.amount +
                    '&amp;return_url=https%3A%2F%2Fportal.wedoc.co.za%2Fmember%2Fconsult%2Fcomplaint&amp;cancel_url=https%3A%2F%2Fportal.wedoc.co.za%2Fmember%2Fconsult'
                  "
                  ><img
                    src="https://www.payfast.co.za/images/buttons/light-small-paynow.png"
                    width="165"
                    height="36"
                    alt="Pay"
                    title="Pay Now with PayFast"
                /></a>

                <!-- Different payment integration -->
                <!-- <ion-button style="width:auto" size="small" type="submit" shape="round"
                  >Confirm Consult</ion-button
                > -->
              </form>
            </div>

            <div class="snapscan" style="hidden"></div>
          </div>

          <!-- <div class="medical">

          </div> -->
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
} from "@ionic/vue";

export default {
  name: "Payment",
  components: {
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonContent,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    tradingHourData() {
      return this.$store.state.admin.tradingHours;
    },
    tradingHours() {      
      if(this.tradingHourData.start_time){
        let start = this.tradingHourData.start_time.split(":");
        let end = this.tradingHourData.end_time.split(":");

        var currentD = new Date();
        var startHour = new Date();
        startHour.setHours(start[0],start[1],0); 
        var endHour = new Date();
        endHour.setHours(end[0],end[1],0); // 5.00 pm
        console.log(currentD);
        console.log(startHour);
        console.log(endHour);

        if(currentD >= startHour && currentD < endHour ){
        return true;
        }else{
          return false;
        }      
      }
      return true;
    },
    tradingStart() {
      if(this.tradingHourData.start_time){
        let start = this.tradingHourData.start_time.split(":");

        var ampm = "am";
        if( start[0] > 12 ) {
            start[0] -= 12;
            ampm = "pm";
        }

        return start[0]+":"+start[1]+ampm;
      }
      return "";
    },
    tradingEnd() {
      if(this.tradingHourData.end_time){
        let end = this.tradingHourData.end_time.split(":");

        var ampm = "am";
        if( end[0] > 12 ) {
            end[0] -= 12;
            ampm = "pm";
        }

        return end[0]+":"+end[1]+ampm;
      }
      return "";
    },
  },
  data() {
    return {
      initial_amount: 300,
      myData: {
        merchant_id: "18798171",
        merchant_key: "4w5hl1mvsznq9",
        // Buyer details
        name_first: "",
        name_last: "",
        email_address: "",
        email_confirmation: 1,
        confirmation_address: this.email_address,
        // Transaction Details
        amount: 300,
        item_name: "Consult",
        signature: "",

        return_url: "http://localhost:8100/member/consult/complaint",
        cancel_url: "http://localhost:8100/member/consult",
      },
      payload: {
        code: "",
        id: 0
      },
    };
  },
  async ionViewDidEnter() {
    await this.$store
      .dispatch("ConsultWaiting", this.user.id)
      .then((res) => {
        console.log(res);
        if (this.$store.state.consults.waitingRoom) {
          this.$router.replace("/member/consult/waitingroom");
        }
      })
      .catch((error) => {
        console.log(error.error);
      });

    this.myData.name_first = this.user.first_name;
    this.myData.name_last = this.user.last_name;
    this.myData.email_address = this.user.email;
  },
  methods: {
    submit: async function () {
      // Check promo code
      document.getElementById("promo-code-message").style.display = "none";
      this.myData.amount = 300;
      await this.$store
        .dispatch("applyPromocode", this.payload)
        .then((res) => {
          let discount = this.initial_amount * (res.response / 100);
          this.myData.amount = this.initial_amount - discount;
          
          if(!(this.myData.amount > 1)){
            this.$router.replace("/member/consult/complaint");
          }
        })
        .catch((error) => {
          document.getElementById("promo-code-message").style.display = "block";
          console.log(error.error);
        });
    },
  },
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 21px;
}

.option {
  border: 1px solid #c7c7c7;
  height: 73px;
  width: 25%;
  border-radius: 7px;
  margin-right: 23px;
  float: left;
}

.selected {
  border: 2px solid var(--ion-color-primary);
}

form {
  padding-top: 102px;
}

h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  margin-top: 34px;
  margin-bottom: 35px;
}
</style>